.journals {
    .journal {
        .image {
            height: 100%;
            position: relative;
            input,
            button {
                position: absolute;
                width: 100%;
                border: 0;
                border-radius: 2px;
                text-transform: capitalize;
                font-size: 15px;
                padding: 8px 0;
                text-decoration: none;
                background-color: #6472da;
                border: none;
                color: var(--text-light);
                font-weight: 400;
                cursor: pointer;
                display: inline-block;
                margin-bottom: 10px;
            }
            input {
                opacity: 0;
            }
            div {
                display: block;
                height: 100%;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
    }
}
