.dashboard {
  .cards {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;

    .card {
      background: #fff;
      padding: 0;
      border-radius: 5px;
      color: #37474f;
      transition: all 300ms ease-in-out;
      cursor: pointer;
      height: 100px;
      box-sizing: border-box;
      display: grid;
      grid-template-columns: 80px 1fr;

      p {
          line-height: 100px;
          text-align: center;
          font-weight: 500;
      }
      strong {
          font-weight: 500;
          display: block;
      }

      > div:first-child > img {
          width: 32px;
          height: 32px;
          margin: 34px auto;
          display: block;
      }

      > div:first-child {
        border-radius: 5px 0 0 5px;
      }

      > div:last-child {
        padding: 20px 15px;
      }

      &.program > div:first-child {
        background-color: #4e73df;
      }
      &.applications > div:first-child {
        background-color: #1cc88a;
      }
      &.points > div:first-child {
        background-color: #d98d37;
      }
      &.payments > div:first-child {
        background-color: #e74a3b;
      }
      &.membership > div:first-child {
        background-color: #36b9cc;
      }
      &.referrals > div:first-child {
        background-color: #f6c23e;
      }
    }
  }

  @media (min-width: 1000px) {
    .cards {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
