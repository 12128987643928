.archives {
    .list {
        display: grid;
        grid-template-columns: auto;

        .archive {
            display: flex;
            border-bottom: 1px solid #c5c5c5;
            padding: 10px 0;
            > div:first-child {
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: 250px;
            }
            > div:nth-child(2) {
                flex-grow: 1;
                padding: 0 10px;
                h4 {
                    font-weight: 500;
                }
            }
            div:last-child {
                display: flex;
                flex-direction: column;
                img {
                    height: 25px;
                    margin: 10px;
                    cursor: pointer;
                }
                .loading {
                    .spinner {
                        width: 2px;
                        height: 2px;
                        border: 2px solid #f3f3f3;
                        border-top: 2px solid #2f4f83;
                    }
                }
            }
        }
    }
}
